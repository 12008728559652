<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">{{ $store.getters['languageMixin/getStringFromLanguage']('Newsletter List') }}</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['newsletter-templates/create'])"
                       :to="'/newsletter-templates/create'"
                       class="btn btn-info btn-wd" wide>
            {{ $store.getters['languageMixin/getStringFromLanguage']('Add New') }}
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>
        <div>
          <general-data-table
            ref="table"
            :row-key="'id'"
            :key="componentKey"
            :api-url="'newsletter-templates/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">

              <template slot-scope="scope">


                <span v-html="scope.row[column.value]"></span>

              </template>

            </el-table-column>
            <el-table-column
              :min-width="120"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <template>
                  <router-link v-if="scope.row.old_id == 0 && scope.row.schedule && isInFuture(scope.row.schedule) && $store.getters['auth/haveOneOfPermissions'](['newsletter-templates/update'])"
                               v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Edit')"
                               class="btn-warning btn-simple btn-link"
                               :to="'/newsletter-templates/edit/'+scope.row.id">
                    <i class="fa fa-edit"></i></router-link>
                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['newsletter-templates/get'])"
                               v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Show')"
                               class="btn-success btn-simple btn-link"
                               :to="'/newsletter-templates/show/'+scope.row.id">
                    <i class="fa fa-eye"></i>
                  </router-link>

<!--                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['newsletter-templates/delete'])"-->
<!--                     v-tooltip.top-center="'Delete'"-->
<!--                     class="btn-danger btn-simple btn-link"-->
<!--                     @click="openDeleteModal(scope.row.id)">-->
<!--                    <i class="fa fa-times"></i></a>-->
                </template>


              </template>
            </el-table-column>
          </general-data-table>
        </div>
      </card>
      <delete-modal :visible="deleteModalVisibility"
                    :message="$store.getters['languageMixin/getStringFromLanguage']('Are you sure you want to delete this newsletter?')"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
    </div>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";


export default {
  components: {
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    FgSelect,
  },

  data() {

    return {
      tableColumns: [
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Number'), value: 'numero', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Title'), value: 'cms_title', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Send at'), value: 'schedule', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Last update / Created at'), value: 'creation_dates', minWidth: '120', align: 'center'},
      ],

      deleteModalVisibility: false,
      toDeleteId: undefined,
      componentKey: 0,
      imagePath: process.env.VUE_APP_SERVER_IMAGE_URL
    }
  },
  methods: {
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    async confirmDeleteModal() {
      try {
        let data = {
            'id': this.toDeleteId,
        }
        await this.axios.delete("newsletter-templates/delete", {headers: {}, data});
        this.$refs.table.getData("updateData");
        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("newsletter deleted successfully"),
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },

    isInFuture(date){
      const now = new Date();
      const itemDate = new Date(date);
      console.log(now)
      console.log(itemDate)
      return itemDate > now;
    }
  },
}
</script>

